import React, { Component } from 'react';
import './header.css';
import Logotype from '../assets/logo-still-light.gif';
import Phone from '../assets/phone-bottom@2x.png';
import Paper from '../assets/background-paper.png';

export default class Header extends Component {
  static propTypes = {};

  render() {
    return (
      <header>
        <div className="header-container" style={{ backgroundImage: 'url(' + Paper + ')' }}>
            <div className="header-container-mobile">
                <div><img id="logotype" alt="logo" src={Logotype} /></div>
                <div className="grid">
                    <div className="grid-item">
                        <img className="phone" src={Phone} alt ="An iPhone with the Skwiz app displayed"/>
                    </div>
                    <div className="grid-item">
                        <h1>The Collaborative Drawing App</h1>
                        <p>Skwiz is the drawing game where multiple people create one drawing without knowledge of what the others have contributed - until the end.</p>
                    </div>
                </div>
            </div>
        </div>
      </header>
    );
  }
}
