import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Home from './Home';
import Terms from './Terms';
import Privacy from './Privacy';
import Support from './Support';

function App() {
  return (
    <div className="App">
        <div className="body">
            <Router>
                <Routes>
                    <Route path='/' element={<Home/>} />
                    <Route path='/terms' element={<Terms/>} />
                    <Route path='/privacy' element={<Privacy/>} />
                    <Route path='/support' element={<Support/>} />
                </Routes>
            </Router>
        </div>
    </div>
  );
}

export default App;
