import React from "react";

const Support = () => {
  return (
    <body className="privacy">
        <h1>Support</h1>
        <br/>

        <h3>About</h3>
        <p>Skwiz is the drawing game where multiple people create one drawing without knowledge of what the others have contributed - until the end.</p>
        <br/>

        <h3>Contact</h3>
        <p>Feel free to contact us with any questions or feedback. We'l try to reply to every message but unfortunately we might not be able to respond to all of them. Thank you for your understanding.</p>
        <a href="mailTo:feedback@skwiz.com">feedback@skwiz.co</a>
        <br/>
        <br/>
        <br/>

        <h3>Legal</h3>
        <a href="./privacy">Privacy Policy</a>
        <br/>
        <a href="./terms">Terms of Service</a>
        <br/>

    </body>
  );
};

export default Support;
