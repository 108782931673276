import React from "react";

const Privacy = () => {
  return (
    <body className="privacy">
        <h1>Privacy Policy</h1>
        <br/>

        <h3>Overview</h3>
        <p>This policy applies to all information collected or submitted on this website and the Skwiz app for iPhone, iPad, and any other devices and platforms.</p>
        <p>We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we’ll protect within commercially acceptable means to prevent loss and theft, as well as unauthorised access, disclosure, copying, use or modification.</p>
        <p>We don’t share any personally identifying information publicly or with third-parties, except when required to by law.</p>
        <p>Your continued use of our app or website will be regarded as acceptance of our practices around privacy and personal information. If you have any questions about how we handle user data and personal information, feel free to contact us.</p>
        <br/>

        <h3>Data Management</h3>
        <p>We use Apple’s iCloud service for creating profiles and facilitating multiplayer games. When logged into iCloud and using our app you agree to the policy and terms of agreement specified in Apple’s listed Terms and Conditions: <a href="https://www.apple.com/legal/internet-services/icloud/" target="_blank" rel="noreferrer">https://www.apple.com/legal/internet-services/icloud/</a>.</p>
        <p>By agreeing to create a profile you are authorizing our app to share your profile and drawings that you have created with other players on the service. Skwiz is not liable for any copying, distributing, or modification of your drawings by other players on the service.</p>
        <br/>

        <h3>Objectionable Content</h3>
        <p>We reserve the right, in our sole discretion, to remove or delete any user generated content on our service that violates these Terms of Service or is otherwise deemed objectionable by us in our sole discretion.</p>

        <h3>Remove Users</h3>
        <p>We reserve the right, in our sole discretion, to remove any user from our service that violates these Terms of Service or has otherwise acted in a manner deemed objectionable by us in our sole discretion.</p>

        <h3>California Online Privacy Protection Act Compliance</h3>
        <p>We comply with the California Online Privacy Protection Act. We therefore will not distribute your personal information to outside parties without your consent.</p>
        <br/>

        <h2>Children’s Online Privacy Protection Act Compliance</h2>
        <p>We never collect or maintain information in our app or website from those we actually know are under 13, and no part of our app or website is structured to attract anyone under 13.</p>
        <br/>

        <h3>Information for European Union Customers</h3>
        <p>By using Skwiz and providing your information, you authorize us to collect, use, and store your information outside of the European Union.</p>
        <br/>

        <h3>International Transfers of Information</h3>
        <p>Information may be processed, stored, and used outside of the country in which you are located. Data privacy laws vary across jurisdictions, and different laws may be applicable to your data depending on where it is processed, stored, or used.</p>
        <br/>

        <h3>Your Consent</h3>
        <p>By using our site or app, you consent to our privacy policy.</p>
        <br/>

        <h3>Contacting Us</h3>
        <p>If you have questions regarding this privacy policy, you may email us at <a href="mailto:feedback@skwiz.co" target="_blank" rel="noreferrer">feedback@skwiz.co</a></p>
        <br/>

        <h3>Changes to this policy</h3>
        <p>If we decide to change our privacy policy, we will post those changes on this page. Summary of changes so far:</p>
        <ul>
            <li>February 28, 2022: First published.</li>
        </ul>

    </body>
  );
};

export default Privacy;
