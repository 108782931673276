import React from "react";
import Header from './components/header.js';
import Footer from './components/footer.js';
import './App.css';

const Home = () => {
  return (
    <div>
        <div>
              <Header />
              <Footer />
        </div>
        <div className="header-background">
            <div className="color1" />
            <div className="color2" />
        </div>
    </div>
  );
};

export default Home;
