import React, { Component } from 'react';
import './footer.css';
// import logotype from '../assets/logo-still-light.gif';
import iconInstagram from '../assets/instagram-light.gif';
import iconTikTok from '../assets/tiktok-light.gif';


export default class Footer extends Component {
  static propTypes = {};

  render() {
    return (
      <footer>
        <div className="footer-container">
            {/*<div className="grid">
                <div><img id="logotype" alt="logo" src={logotype} /></div>
                <div className="lists">
                    <div className="list">

                        <span>Get in Touch</span>
                        <ul>
                            <li><a href="mailTo:michael@skwiz.co">Contact</a></li>
                            {/*<li><a>Press</a></li>
                        </ul>
                    </div>

                        <div className="list">
                        <span>More Skwiz</span>
                        <ul>
                            <li><a href="./about">About</a></li>
                            <li><a href="./frequently-asked-questions">FAQ</a></li>
                            <li><a href="./shop">Shop</a></li>
                        </ul>
                    </div>

                </div>
            </div>*/}
        </div>

        <div id="subfooter" className="footer-container">
            <div className="grid">
                <div id="social-links">
                    <a className="icon-link" href="https://www.instagram.com/skwizcorpse/" target="_blank" rel="noreferrer"><img className="icon" alt="Instagram" src={iconInstagram} /></a>
                    <a className="icon-link" href="https://www.tiktok.com/@skwizcorpse" target="_blank" rel="noreferrer"><img className="icon" alt="Tik Tok" src={iconTikTok} /></a>
                </div>
                <div id="privacy-links">
                  <a href="mailTo:press@skwiz.co">Contact</a>
                  <a href="./privacy">Privacy Policy</a>
                  <a href="./terms">Terms</a>
                  <a href="https://jerks.ink/" target="_blank" rel="noreferrer"><span class="copyright">© <script>document.write(new Date().getFullYear())</script> jERKS iNC</span></a>
              </div>
            </div>
        </div>

      </footer>
    );
  }
}
